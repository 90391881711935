import { inject } from 'vue';

import { IChapter } from '@/support/models/chapter.interface';
import { IUnit } from '@/support/models/unit.interface';

import { StrapiService } from '@/support/services/strapi.service';
import { ICourse } from '@/support/models/course.interface';

export function useData() {
  const service: StrapiService | undefined = inject('strapiService');

  const getCoursesList = async (): Promise<ICourse[]> => {
    const courses: ICourse[] | undefined = await service?.getCourses();

    return courses ?? [];
  };

  const getCourseData = async (
    courseId: number
  ): Promise<ICourse | undefined> => {
    const course: ICourse | undefined = await service?.getCourse(courseId);

    return course;
  };

  const getUnitData = async (
    courseId: number,
    unitId: number
  ): Promise<IUnit | undefined> => {
    const unit: IUnit | undefined = await service?.getUnit(courseId, unitId);

    return unit;
  };

  const getChapterData = async (
    courseId: number,
    unitId: number,
    chapterId: number
  ): Promise<IChapter | undefined> => {
    const chapter: IChapter | undefined = await service?.getChapter(
      courseId,
      unitId,
      chapterId
    );

    return chapter;
  };

  return {
    getChapterData,
    getCourseData,
    getCoursesList,
    getUnitData,
  };
}
