import { ICourseletResponse } from '../interfaces';
import { ICourselet } from '../models/courselet.interface';

const MAX_SORTING_VALUE = 999;

export const mapCourselet = (courselet: ICourseletResponse): ICourselet => {
  const media = courselet.media.data?.map((media) => media.attributes);
  const quiz = courselet.quiz;
  const text = courselet.text;
  const video = courselet.video;
  const lottie = courselet.lottie;
  const id = courselet.id;

  return {
    id,
    lottie,
    media,
    quiz,
    text,
    video,
    sortMedia: Number(courselet.sortMedia ?? MAX_SORTING_VALUE),
    sortQuiz: Number(courselet.sortQuiz ?? MAX_SORTING_VALUE),
    sortText: Number(courselet.sortText ?? MAX_SORTING_VALUE),
    sortVideo: Number(courselet.sortVideo ?? MAX_SORTING_VALUE),
    sortLottie: Number(courselet.sortLottie ?? MAX_SORTING_VALUE),
  } as ICourselet;
};
