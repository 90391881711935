import axios, { AxiosInstance } from 'axios';

import { BaseService } from './base.service';
import {
  IResponse,
  IStrapiData,
  IChapterResponse,
  IUnitResponse,
  ICourseResponse,
} from '@/support/interfaces';

import { ICourse } from '@/support/models/course.interface';
import { IChapter } from '@/support/models/chapter.interface';
import { IUnit } from '@/support/models/unit.interface';
import { ICourselet } from '@/support/models/courselet.interface';
import { mapCourselet } from '@/support/utils/courselet';
import { mapCourses } from '@/support/utils/course';

export class StrapiService extends BaseService {
  constructor() {
    const api: AxiosInstance = (() => {
      const strapiAPI = axios.create({
        baseURL: `${process.env.VUE_APP_STRAPI_URL}/api`,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${process.env.VUE_APP_STRAPI_API_KEY}`,
        },
      });

      return strapiAPI;
    })();

    super(api);
  }

  async getCourses(): Promise<ICourse[]> {
    return await this.api
      .get('/courses', {
        params: {
          populate: 'image,units',
        },
      })
      .then((response: IResponse<IStrapiData<ICourseResponse>[]>) => {
        return response.data.data.map(mapCourses);
      });
  }

  async getCourse(courseId: number): Promise<ICourse> {
    return await this.api
      .get(`/courses/${courseId}`, {
        params: {
          populate: 'units.chapters',
        },
      })
      .then((response: IResponse<IStrapiData<ICourse>>) => ({
        ...response.data.data.attributes,
        id: response.data.data.id,
      }));
  }

  async getUnit(courseId: number, unitId: number): Promise<IUnit | undefined> {
    return await this.api
      .get(`/courses/${courseId}`, {
        params: {
          populate: 'units.chapters.courselets',
        },
      })
      .then((response: IResponse<IStrapiData<ICourse>>) => {
        const course: ICourse = response.data.data.attributes;

        const unit: IUnit | undefined = course.units.find(
          (unit) => unit.id === unitId
        );

        return unit;
      });
  }

  async getChapter(
    courseId: number,
    unitId: number,
    chapterId: number
  ): Promise<IChapter | undefined> {
    const populate = [
      '*',
      'units.chapters.courselets',
      'units.chapters.courselets.quiz.choices',
      'units.chapters.courselets.media',
      'units.chapters.courselets.video',
      'units.chapters.courselets.lottie',
    ];

    return await this.api
      .get(`/courses/${courseId}`, {
        params: {
          populate,
        },
      })
      .then((response: IResponse<IStrapiData<ICourseResponse>>) => {
        const course: ICourseResponse = response.data.data.attributes;

        const unit: IUnitResponse | undefined = course.units.find(
          (unit) => unit.id === unitId
        );

        const chapter: IChapterResponse | undefined = unit?.chapters.find(
          (chapter) => chapter.id === chapterId
        );

        if (!chapter) {
          return;
        }

        const courselets = chapter.courselets.map(mapCourselet) as ICourselet[];

        return {
          id: chapter.id,
          name: chapter.name,
          courselets,
        };
      });
  }
}
