import { ICourseResponse, IStrapiData } from '../interfaces';
import { ICourse } from '../models/course.interface';
import { IUnit } from '../models/unit.interface';

export const mapCourses = (response: IStrapiData<ICourseResponse>): ICourse => {
  const {
    id,
    attributes: { name, excerpt, units, image },
  } = response;

  const imageProp = image.data?.attributes;
  const unitsProp: IUnit[] =
    units?.map((unit) => ({ ...unit, chapters: [] })) ?? [];

  return {
    id,
    name,
    excerpt,
    image: imageProp,
    units: unitsProp,
  };
};
