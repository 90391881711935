import { defineStore } from 'pinia';
import { ref } from 'vue';

import { IStoreChapters } from '@/support/interfaces/store';
import { IChapter } from '@/support/models/chapter.interface';

import { useCourseletStore } from '@/stores/courselet';

export const useChapterStore = defineStore('chapter', () => {
  const { reset } = useCourseletStore();

  const currentChapter = ref<IChapter>();
  const chapters = ref<IStoreChapters>({});

  function isCompleted(chapterId: number): boolean {
    return chapters.value[chapterId]?.completed ?? false;
  }

  function isStarted(chapterId: number): boolean {
    return chapters.value[chapterId] ? true : false;
  }

  function getLastCourselet(chapterId: number): number | null {
    return chapters.value[chapterId]?.lastCourselet ?? null;
  }

  function setChapterProgress(chapterId: number, courseletId: number): void {
    if (chapters.value[chapterId]) {
      chapters.value[chapterId].lastCourselet = courseletId;
    } else {
      chapters.value[chapterId] = {
        lastCourselet: courseletId,
        completed: false,
      };
    }
  }

  function setChapterCompleted(chapterId: number): void {
    if (chapters.value[chapterId]) {
      chapters.value[chapterId].completed = true;
    }
  }

  function setCurrentChapter(chapter: IChapter): void {
    currentChapter.value = chapter;
  }

  function retryChapter(chapter: IChapter): void {
    for (const courselet of chapter.courselets) {
      reset(courselet.id);
    }

    chapters.value[chapter.id] = {
      completed: false,
      lastCourselet: 0,
    };
  }

  return {
    chapters,
    currentChapter,

    setCurrentChapter,
    retryChapter,
    getLastCourselet,
    setChapterProgress,
    setChapterCompleted,
    isCompleted,
    isStarted,
  };
});
