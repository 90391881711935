import { IChapter } from '@/support/models/chapter.interface';
import { useCourseletStore } from '@/stores/courselet';
import { useChapterStore } from '@/stores/chapter';

export function useProgress() {
  const { getSelectedAnswer } = useCourseletStore();
  const { chapters, getLastCourselet } = useChapterStore();

  const countCorrectAnswers = (chapter: IChapter): number => {
    return chapter.courselets.reduce((count, courselet) => {
      if (courselet.quiz) {
        const isCorrect = getSelectedAnswer(courselet.id).every((choice) =>
          courselet.quiz.result.split(',').includes(choice.value)
        );

        if (isCorrect) {
          return count + 1;
        }
      }

      return count;
    }, 0);
  };

  const countQuizzes = (chapter: IChapter): number => {
    return chapter.courselets.filter((courselet) => courselet.quiz).length;
  };

  const lastCourseletIndex = (chapter: IChapter): number => {
    return chapter.courselets.findIndex(
      (courselet) => courselet.id === getLastCourselet(chapter.id)
    );
  };

  const readCourseletsCount = (chapter: IChapter): number => {
    return lastCourseletIndex(chapter) + 1;
  };

  const listOfChapters = (
    chapterIds: number[]
  ): { lastCourselet: number; completed: boolean }[] => {
    return chapterIds
      .filter((key: string | number) => key in chapters)
      .reduce(
        (result: any, key: number) => ((result[key] = chapters[key]), result),
        {}
      );
  };

  return {
    countCorrectAnswers,
    countQuizzes,
    lastCourseletIndex,
    readCourseletsCount,
    listOfChapters,
  };
}
