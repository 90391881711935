import { h } from 'vue';

import { IonCol, IonRow } from '@ionic/vue';

import { SectionType } from '@/support/models/courselet.interface';

import '@/assets/rich-text.css';

export function useText(innerHTML: string) {
  const component = h(IonRow, { class: 'text-block' }, () =>
    h(IonCol, {
      class: 'rich-text',
      innerHTML,
    })
  );

  return { component, type: SectionType.Text };
}
