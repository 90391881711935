import { h } from 'vue';

import { SectionType } from '@/support/models/courselet.interface';
import { IMedia } from '@/support/models/media.interface';

import VideoWrapper from '@/components/Common/VideoWrapper.vue';

const VIDEO_MIME_TYPE = 'video/mp4';

export function useMedia(media: IMedia[]) {
  const components = media.map((media) =>
    media.mime === VIDEO_MIME_TYPE
      ? h(VideoWrapper, {
          src: media.url,
        })
      : h('img', { src: media.url, class: 'media' })
  );

  return { components, type: SectionType.Media };
}
