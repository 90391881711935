import { IQuiz } from './quiz.interface';
import { IMedia } from './media.interface';
import { IVideo } from './video.interface';

export enum SectionType {
  Lottie = 'lottie',
  Media = 'media',
  Quiz = 'quiz',
  Text = 'text',
  Video = 'video',
}

export interface ICourselet {
  id: number;
  text: string;
  lottie: string;
  media: IMedia[];
  quiz: IQuiz;
  video: IVideo;
  sortLottie: number;
  sortMedia: number;
  sortQuiz: number;
  sortText: number;
  sortVideo: number;
}
