import { AxiosInstance } from 'axios';

export class BaseService {
  constructor(protected api: AxiosInstance, protected route?: string) {
    this.setApi(api);

    if (route) {
      this.route = route;
    }
  }

  protected setApi(api: AxiosInstance) {
    this.api = api;

    return this;
  }

  static newInstance(...args: any) {
    let instance = null;

    return (() => {
      if (instance === null) {
        instance = new this(args);
        // Hide the constructor so the returned object can't be new'd...
        instance.constructor = () => ({});
      }

      return instance;
    })();
  }
}
