import { h } from 'vue';

import { IonCol, IonRow } from '@ionic/vue';

import CourseletChoice from '@/components/Courselet/CourseletChoice.vue';

import { useCourseletStore } from '@/stores/courselet';
import { SectionType } from '@/support/models/courselet.interface';
import { IQuiz } from '@/support/models/quiz.interface';
import { QuizLayout } from '@/support/types/quiz';

export function useQuiz(quiz: IQuiz, courseletId: number) {
  const { selectChoice, unselectChoice, isSelected } = useCourseletStore();
  const quizColumnSize = {
    [QuizLayout.Row]: '6',
    [QuizLayout.Column]: '12',
  };

  const choices = quiz.choices.map((choice) =>
    h(
      IonCol,
      {
        size: quizColumnSize[quiz.layout],
      },
      () =>
        h(CourseletChoice, {
          ...choice,
          checked: isSelected(courseletId, choice),
          onChecked: (event: { checked: boolean; value: string }) => {
            if (event.checked) {
              selectChoice(courseletId, choice);
            } else {
              unselectChoice(courseletId, choice);
            }
          },
        })
    )
  );

  const component = h('section', null, [
    h('h1', null, quiz.title),
    h('h4', null, quiz.description),
    h(IonRow, {}, () => choices),
  ]);

  return { component, type: SectionType.Quiz };
}
