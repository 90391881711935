import { defineStore } from 'pinia';
import { ref } from 'vue';

import { IStoreCourselets } from '@/support/interfaces/store';
import { IChoice } from '@/support/models/choice.interface';

export const useCourseletStore = defineStore('courselet', () => {
  const courselets = ref<IStoreCourselets>({});

  function getSelectedAnswer(courselet: number): IChoice[] {
    return courselets.value[courselet]?.selected ?? [];
  }

  function isSelected(courselet: number, choice: IChoice): boolean {
    const selectedChoice = getSelectedAnswer(courselet).find(
      (selected) => selected.value === choice.value
    );

    if (selectedChoice) {
      return selectedChoice.value === choice.value;
    }

    return false;
  }

  function reset(courseletId: number): void {
    if (courselets.value[courseletId]) {
      courselets.value[courseletId].selected = [];
    }
  }

  function selectChoice(courselet: number, choice: IChoice): void {
    if (!courselets.value[courselet]) {
      courselets.value[courselet] = { selected: [] };
    }

    courselets.value[courselet].selected = [
      ...(getSelectedAnswer(courselet) ?? []),
      choice,
    ];
  }

  function unselectChoice(courselet: number, choice: IChoice): void {
    const index = getSelectedAnswer(courselet).findIndex(
      (selected) => selected.value === choice.value
    );

    if (index > -1) {
      courselets.value[courselet]?.selected.splice(index, 1);
    }
  }

  return {
    courselets,

    reset,
    isSelected,
    getSelectedAnswer,
    selectChoice,
    unselectChoice,
  };
});
