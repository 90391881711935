import { h } from 'vue';

import { IonCol, IonRow } from '@ionic/vue';

import VideoEmbedWrapper from '@/components/Common/VideoEmbedWrapper.vue';
import { SectionType } from '@/support/models/courselet.interface';
import { IVideo } from '@/support/models/video.interface';

export function useVideo(video: IVideo) {
  const component = h(IonRow, { style: 'align-items: center' }, () =>
    h(IonCol, null, () => h(VideoEmbedWrapper, video))
  );

  return { component, type: SectionType.Video };
}
