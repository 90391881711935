import { computed, ref } from 'vue';

import { IChapter } from '@/support/models/chapter.interface';
import { ICourselet } from '@/support/models/courselet.interface';

export function useCourselet() {
  const activeChapter = ref<IChapter>();
  const activeCourselet = ref<ICourselet>();
  const slidesCompleted = ref<ICourselet[]>([]);

  const isQuiz = computed((): boolean => Boolean(activeCourselet.value?.quiz));
  const isLastSlide = computed(
    (): boolean =>
      activeChapter.value?.courselets.at(-1)?.id === activeCourselet.value?.id
  );

  const canSwipeNext = computed(() =>
    slidesCompleted.value.some(
      (slideCompleted) => slideCompleted.id === activeCourselet.value?.id
    )
  );

  function setCompletedCourselet(courselet: ICourselet): void {
    slidesCompleted.value.push(courselet);
  }

  function setActiveCourselet(index: number): void {
    if (activeChapter.value?.courselets[index]) {
      activeCourselet.value = activeChapter.value?.courselets[index];
    }
  }

  function setActiveChapter(chapter: IChapter): void {
    activeChapter.value = chapter;
  }

  return {
    isQuiz,
    isLastSlide,
    canSwipeNext,

    slidesCompleted,
    setCompletedCourselet,

    activeCourselet,
    setActiveCourselet,

    activeChapter,
    setActiveChapter,
  };
}
