import { h } from 'vue';

import { IonCol, IonRow } from '@ionic/vue';

import '@lottiefiles/lottie-player';

import { SectionType } from '@/support/models/courselet.interface';

export function useLottie(lottie: string) {
  const component = h(IonRow, {}, () =>
    h(IonCol, {}, () =>
      h('lottie-player', {
        autoplay: true,
        controls: false,
        loop: true,
        mode: 'normal',
        src: lottie,
        class: 'lottie',
      })
    )
  );

  return { component, type: SectionType.Lottie };
}
