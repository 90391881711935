import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import CoursePage from '../views/CoursePage.vue';
import UnitPage from '../views/UnitPage.vue';
import ChapterPage from '../views/ChapterPage.vue';
import ChapterFinishPage from '../views/ChapterFinishPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/course/:course',
    name: 'Course',
    component: CoursePage,
  },
  {
    path: '/course/:course/unit/:unit',
    name: 'Unit',
    component: UnitPage,
  },
  {
    path: '/course/:course/unit/:unit/chapter/:chapter',
    name: 'Chapter',
    component: ChapterPage,
  },
  {
    path: '/course/:course/unit/:unit/chapter/:chapter/finish',
    name: 'ChapterFinish',
    component: ChapterFinishPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
